import { Button, Dialog, DialogActions, DialogContent, Typography } from "@material-ui/core";
import { WarningRounded } from "@material-ui/icons";
import { useViolationModalStyles } from "./ViolationModalStyle";

interface IProps {
    open: boolean;
    handleClose: (e?: any, r?: string) => void;
    record: {
        message: string;
        suspendedUntil: string;
        suspensionTime: string;
    };
}

const ViolationModal = ({ open, handleClose, record }: IProps) => {
    const classes = useViolationModalStyles();

    return (
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} disableEscapeKeyDown>
            <DialogContent dividers>
                <Typography variant="h4" className={classes.title}><WarningRounded fontSize="large" color="error" /> Warning!!</Typography>
                <Typography variant="h6" align="center" gutterBottom>{record?.message}</Typography>
                <Typography variant="h6" align="center" gutterBottom>You can try again after {record?.suspensionTime}</Typography>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleClose} color="primary">
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ViolationModal;
