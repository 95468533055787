import React, { FC, ReactElement, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchDeclinedTweets } from "../../store/ducks/tweets/actionCreators";
import {
    selectDeclinedIsTweetsLoading,
    selectDeclinedPageCount,
    selectDeclinedTweetState
} from "../../store/ducks/tweets/selectors";
import { selectUserDataId } from "../../store/ducks/user/selectors";
import TweetComponent from "../../components/TweetComponent/TweetComponent";
import { Paper, Typography } from "@material-ui/core";
import classNames from "classnames";
import { useGlobalStyles } from "../../util/globalClasses";
import { useDeclinedStyles } from "./DeclinedTweetStyles";
import DeclinedPageTweetHeader from "./DeclinedPageTweetHeader/DeclinedPageTweetHeader";
import Spinner from "../../components/Spinner/Spinner";
import InfiniteScrollWrapper from "../../components/InfiniteScrollWrapper/InfiniteScrollWrapper";

const DeclinedTweets: FC = (): ReactElement => {
    const globalClasses = useGlobalStyles({});
    const classes = useDeclinedStyles();
    const dispatch = useDispatch();
    const myProfileId = useSelector(selectUserDataId);
    const declinedTweets = useSelector(selectDeclinedTweetState);
    const isLoading = useSelector(selectDeclinedIsTweetsLoading);
    const pagesCount = useSelector(selectDeclinedPageCount);

    const [page, setPage] = React.useState<number>(0);

    const fetchNewDeclinedTweets = () => {
        dispatch(fetchDeclinedTweets({ userId: myProfileId, pageNumber: page }));
        setPage((prev: number) => prev + 1);
    };

    useEffect(() => {
        if (myProfileId) {
            fetchNewDeclinedTweets();
        }
    }, [myProfileId]);

    return (
        <Paper className={classNames(globalClasses.pageContainer, classes.container)} variant="outlined">
            {isLoading ? (
                <Spinner />
            ) : (
                <>
                    <DeclinedPageTweetHeader />
                    <div className={globalClasses.contentWrapper}>
                        {declinedTweets.length === 0 && (
                            <div className={classes.textWrapper}>
                                <Typography variant={"h5"}>You don't have any rejected tweets yet</Typography>
                                <Typography variant={"subtitle1"}>
                                    When your tweets get rejected, they’ll show up here.
                                </Typography>
                            </div>
                        )}
                        <InfiniteScrollWrapper
                            dataLength={declinedTweets.length}
                            pagesCount={pagesCount}
                            loadItems={fetchNewDeclinedTweets}
                        >
                            {declinedTweets.map((declinedTweet: any) => (
                                <TweetComponent
                                    key={declinedTweet?.id}
                                    tweet={declinedTweet}
                                    appeal={declinedTweet?.appealResponse}
                                    webhookResponse={declinedTweet?.webhookResponse}
                                    isDeclined
                                />
                            ))}
                        </InfiniteScrollWrapper>
                    </div>
                </>
            )}
        </Paper>
    );
};

export default DeclinedTweets;
