import React, { FC, memo, ReactElement } from "react";
import { ClickAwayListener, IconButton, List, ListItem, Tooltip, Typography, withStyles } from "@material-ui/core";
import { useParams } from "react-router-dom";
import classnames from "classnames";

import { useQuoteIconButtonStyles } from "./QuoteIconButtonSyles";
import { QuoteTweetIcon, RetweetIcon, RetweetOutlinedIcon } from "../../icons";
import QuoteTweetModal from "./QuoteTweetModal/QuoteTweetModal";
import { useGlobalStyles } from "../../util/globalClasses";
import { QuoteTweetResponse, UserTweetResponse } from "../../types/tweet";
import ActionIconButton from "../ActionIconButton/ActionIconButton";
import { retweet } from "../../store/ducks/tweets/actionCreators";
import { useDispatch, useSelector } from "react-redux";
import { selectUserDataId } from "../../store/ducks/user/selectors";
import { useModalWindow } from "../../hook/useModalWindow";
import { useClickAway } from "../../hook/useClickAway";
import classNames from "classnames";

export interface QuoteTweetProps {
    tweetId?: string;
    createdAt?: string;
    text?: string;
    author?: UserTweetResponse;
    isTweetRetweeted?: boolean;
    retweetsCount?: number;
    isExplore?: boolean;
    isDisabled?: boolean;
}

const QuoteIconButton: FC<QuoteTweetProps> = memo((
    {
        tweetId,
        createdAt,
        text,
        author,
        isTweetRetweeted,
        retweetsCount,
        isExplore,
        isDisabled
    }
): ReactElement => {
    const globalClasses = useGlobalStyles({});
    const classes = useQuoteIconButtonStyles({ isTweetRetweetedByMe: isTweetRetweeted });

    const [isRetweeted, setRetweeted] = React.useState(isTweetRetweeted);
    const [count, setCount] = React.useState<number>(retweetsCount || 0);
    const [animationLike, setAnimationLike] = React.useState(globalClasses.countInit);

    const dispatch = useDispatch();
    const params = useParams<{ userId: string }>();
    const myProfileId = useSelector(selectUserDataId);
    const { open, onClickOpen, onClickClose } = useClickAway();
    const { visibleModalWindow, onOpenModalWindow, onCloseModalWindow } = useModalWindow();

    const handleCounts = () => {
        setRetweeted(!isRetweeted);
        setCount((prevState) => isRetweeted ? --prevState : ++prevState);
        setAnimationLike(isRetweeted ? globalClasses.countGoDown : globalClasses.countGoUp);
        setTimeout(() => setAnimationLike(globalClasses.countInit), 100); // Set 100 milliseconds time becasuse of the animation delay is 0.1s
    }

    const onClickRetweet = (): void => {
        if (author?.id !== myProfileId) {
            handleCounts()
            dispatch(retweet({ tweetId: tweetId!, userId: params.userId ?? myProfileId, isExplore }));
        }
        onClickClose();
    };

    const handleClickOpenAddTweet = (): void => {
        onOpenModalWindow();
        onClickClose();
    };

    // Update like counts on props changes
    React.useEffect(() => {
        if (retweetsCount !== undefined && retweetsCount !== count) {
            setCount(retweetsCount);
            setAnimationLike(retweetsCount < count ? globalClasses.countGoDown : globalClasses.countGoUp);
            setTimeout(() => setAnimationLike(globalClasses.countInit), 100); // Set 100 milliseconds time becasuse of the animation delay is 0.1s
        }
    }, [retweetsCount]);

    const BlackToolTip = withStyles({
        tooltip: {
          backgroundColor: "rgba(0, 0, 0, 0.6)"
        }
      })(Tooltip);

    return (
        <ClickAwayListener onClickAway={onClickClose}>
            <div className={classes.footerIcon}>
                {
                    isDisabled ? (
                        <BlackToolTip title="Retweets are disabled for this post.">
                            <IconButton aria-label="Retweets are disabled for this post.">
                                <>{RetweetOutlinedIcon}</>
                            </IconButton>
                        </BlackToolTip>
                    ) : (
                        <ActionIconButton
                            actionText={isRetweeted ? "Undo Retweet" : "Retweet"}
                            icon={isRetweeted ? RetweetIcon : RetweetOutlinedIcon}
                            onClick={onClickOpen}
                            disabled={isDisabled}
                        />
                    )
                }
                {(count !== 0) && (
                    <span id={"retweets"} className={classNames(classes.retweetsCount, animationLike)}>
                        {count}
                    </span>
                )}
                {open && (
                    <div className={classnames(classes.dropdown, globalClasses.svg)}>
                        <List>
                            {author?.id !== myProfileId && (
                                <ListItem id={"clickRetweet"} onClick={onClickRetweet}>
                                    <>{RetweetOutlinedIcon}</>
                                    <Typography variant={"body1"} component={"span"}>
                                        {isTweetRetweeted ? ("Undo Retweet") : ("Retweet")}
                                    </Typography>
                                </ListItem>
                            )}
                            <ListItem id={"clickOpenAddTweet"} onClick={handleClickOpenAddTweet}>
                                <>{QuoteTweetIcon}</>
                                <Typography variant={"body1"} component={"span"}>
                                    Quote Tweet
                                </Typography>
                            </ListItem>
                        </List>
                    </div>
                )}
                <QuoteTweetModal
                    quoteTweet={{ id: tweetId, createdAt, text, author } as QuoteTweetResponse}
                    onClose={onCloseModalWindow}
                    visible={visibleModalWindow}
                />
            </div>
        </ClickAwayListener>
    );
});

export default QuoteIconButton;
