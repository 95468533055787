import { makeStyles } from "@material-ui/core";

export const useSetPasswordModalStyles = makeStyles(() => ({
    title: {
        marginTop: 20
    },
    subtitle: {
        marginTop: 15
    },
    controllerWrapper: {
        marginTop: 10,
        marginBottom: 20
    },
    input: {
        marginBottom: 24,
        position: "relative",
        "& .MuiIconButton-root": {
            minWidth: "fit-content",
            minHeight: "fit-content",
            fontSize: 20,
            cursor: "pointer",
            position: "absolute",
            top: "38%",
            right: 0,
            transform: "translate(-50%, -50%)",
        },
        "& svg": {
            color: "#536471",
            marginTop: 0,
        },
    },
}));
