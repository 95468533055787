import React, { memo, ReactElement } from "react";
import Typography from "@material-ui/core/Typography";
import { useSelector } from "react-redux";

import { textFormatter } from "../../../util/text-formatter";
import { selectTweetContentBlurred, selectTweetText } from "../../../store/ducks/tweet/selectors";
import { useTweetTextStyles } from "./TweetTextStyles";

const TweetText = memo((): ReactElement => {
    const classes = useTweetTextStyles();
    const tweetText = useSelector(selectTweetText);
    const isContentblurred = useSelector(selectTweetContentBlurred);

    return (
        <Typography variant={"h3"} className={classes.text} style={{ filter: isContentblurred ? "blur(5px)" : "none" }}>
            {textFormatter(tweetText!)}
        </Typography>
    );
});

export default TweetText;
