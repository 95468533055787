import React, { memo, ReactElement } from "react";
import { useSelector } from "react-redux";
import Skeleton from "@material-ui/lab/Skeleton";
import { Typography } from "@material-ui/core";

import { LockIcon } from "../../../icons";
import {
    selectUserDisciplinaryCard,
    selectUserProfileAbout,
    selectUserProfileFullName,
    selectUserProfileId,
    selectUserProfileIsMyProfileBlocked,
    selectUserProfileIsPrivateProfile,
    selectUserProfileUsername
} from "../../../store/ducks/userProfile/selectors";
import { useUserPageStyles } from "../UserPageStyles";
import { APP_NAME } from "../../../constants/common-constants";

const UserInfo = memo((): ReactElement => {
    const classes = useUserPageStyles();
    const userProfileId = useSelector(selectUserProfileId);
    const fullName = useSelector(selectUserProfileFullName);
    const username = useSelector(selectUserProfileUsername);
    const about = useSelector(selectUserProfileAbout);
    const isPrivateProfile = useSelector(selectUserProfileIsPrivateProfile);
    const isMyProfileBlocked = useSelector(selectUserProfileIsMyProfileBlocked);
    const disiplanryCard = useSelector(selectUserDisciplinaryCard);

    return (
        <>
            {!userProfileId ? (
                <Skeleton variant="text" width={250} height={30} />
            ) : (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px"
                    }}
                >
                    <Typography variant={"h5"} component={"span"}>
                        {fullName}
                    </Typography>
                    {isPrivateProfile && <span className={classes.lockIcon}>{LockIcon}</span>}
                    {disiplanryCard === "YELLOW" && (
                        <div
                            title={`${fullName} have received a public Yellow Card from the ${APP_NAME} Referee, for violating the ${APP_NAME} Rules.`}
                            style={{
                                background: "yellow",
                                height: "18px",
                                width: "15px"
                            }}
                        />
                    )}
                    {disiplanryCard === "RED" && (
                        <div
                            title={`${fullName} have received a public Red Card from the ${APP_NAME} Referee, for violating the ${APP_NAME} Rules.`}
                            style={{
                                background: "red",
                                height: "18px",
                                width: "15px"
                            }}
                        />
                    )}
                </div>
            )}
            {!userProfileId ? (
                <Skeleton variant="text" width={60} />
            ) : (
                <Typography variant={"subtitle1"} component={"div"}>
                    @{username}
                </Typography>
            )}
            {!isMyProfileBlocked && (
                <Typography variant={"body1"} component={"div"} className={classes.description}>
                    {about}
                </Typography>
            )}
        </>
    );
});

export default UserInfo;
