import { makeStyles } from "@material-ui/core";

export const useDeclinedStyles = makeStyles((theme) => ({
    container: {
        "& .MuiTab-root": {
            textTransform: "none !important",
            minWidth: "150px !important",
            padding: "14px 12px !important"
        }
    },
    textWrapper: {
        margin: "40px 20px",
        textAlign: "center",
        "& .MuiTypography-subtitle1": {
            marginTop: 12
        }
    },
}))