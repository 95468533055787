import React, { FC, memo, ReactElement } from "react";

import { UserTweetResponse } from "../../../types/tweet";
import ActionIconButton from "../../ActionIconButton/ActionIconButton";
import { DeleteAccountIcon, DeleteIcon, ReplyIcon } from "../../../icons";
import ReplyModal from "../../ReplyModal/ReplyModal";
import { Image } from "../../../types/common";
import { useReplyIconButtonStyles } from "./ReplyIconButtonStyles";
import { useModalWindow } from "../../../hook/useModalWindow";
import { useGlobalStyles } from "../../../util/globalClasses";
import classNames from "classnames";
import { IconButton, Tooltip, withStyles } from "@material-ui/core";

interface TweetReplyIconButtonProps {
    tweetId?: string;
    text?: string;
    image?: Image;
    createdAt?: string;
    tweetAuthor?: UserTweetResponse;
    repliesCount?: number;
    isUserCanReply: boolean;
    isExplore?: boolean;
    blurredContent?: boolean;
}

const ReplyIconButton: FC<TweetReplyIconButtonProps> = memo((
    {
        tweetId,
        text,
        image,
        createdAt,
        tweetAuthor,
        repliesCount,
        isUserCanReply,
        isExplore,
        blurredContent
    }
): ReactElement => {
    const globalClasses = useGlobalStyles({});
    const classes = useReplyIconButtonStyles({ isUserCanReply });

    const [count, setCount] = React.useState<number>(repliesCount || 0);
    const [animationLike, setAnimationLike] = React.useState(globalClasses.countInit);

    const { visibleModalWindow, onOpenModalWindow, onCloseModalWindow } = useModalWindow();

    React.useEffect(() => {
        if (repliesCount !== undefined && repliesCount !== count) {
            setCount(repliesCount);
            setAnimationLike(repliesCount > count ? globalClasses.countGoUp : globalClasses.countGoDown);
            setTimeout(() => setAnimationLike(globalClasses.countInit), 100); // Set 100 milliseconds time becasuse of the animation delay is 0.1s
        }
    }, [repliesCount]);

    const BlackToolTip = withStyles({
        tooltip: {
          backgroundColor: "rgba(0, 0, 0, 0.6)"
        }
      })(Tooltip);

    return (
        <div className={classes.replyIcon}>
            {isUserCanReply ? (
                <BlackToolTip title="Replies are disabled for this post.">
                    <IconButton aria-label="Replies are disabled for this post.">
                        <>{<ReplyIcon />}</>
                    </IconButton>
                </BlackToolTip>
            ) : (
                <ActionIconButton
                    actionText={"Reply"}
                    icon={<ReplyIcon />}
                    onClick={onOpenModalWindow}
                    disabled={isUserCanReply}
                />
            )}

            {count !== 0 && (
                <span id={"repliesCount"} className={classNames(classes.repliesCount, animationLike)}>
                    {count}
                </span>
            )}
            <ReplyModal
                author={tweetAuthor!}
                tweetId={tweetId!}
                text={text!}
                image={image}
                createdAt={createdAt!}
                visible={visibleModalWindow}
                onClose={onCloseModalWindow}
                isExplore={isExplore}
                blurredContent={blurredContent}
            />
        </div>
    );
});

export default ReplyIconButton;
