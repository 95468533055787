import React, { FC, ReactElement, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { Divider, Paper, Typography } from "@material-ui/core";
import classnames from "classnames";
import TweetComponent from "../../components/TweetComponent/TweetComponent";
import { useHomeStyles } from "./HomeStyles";
import AddTweetForm from "../../components/AddTweetForm/AddTweetForm";
import {
    fetchFollowersTweets,
    fetchTweets,
    resetTweets,
    setTweetCountReset,
    setTweetsLoadingState
} from "../../store/ducks/tweets/actionCreators";
import {
    selectIsTweetsLoading,
    selectNewTweetCount,
    selectPagesCount,
    selectTweetsItems
} from "../../store/ducks/tweets/selectors";
import { fetchUserData } from "../../store/ducks/user/actionCreators";
import { selectUserDataIsProfileStarted } from "../../store/ducks/user/selectors";
import Welcome from "../../components/Welcome/Welcome";
import Spinner from "../../components/Spinner/Spinner";
import { useGlobalStyles } from "../../util/globalClasses";
import TopTweetActions from "./TopTweetActions/TopTweetActions";
import { withDocumentTitle } from "../../hoc/withDocumentTitle";
import { SEARCH } from "../../constants/path-constants";
import { LoadingStatus } from "../../types/common";
import { SHOW_POST } from "../../constants/common-constants";

const Home: FC = (): ReactElement => {
    const globalClasses = useGlobalStyles({});
    const classes = useHomeStyles();
    const dispatch = useDispatch();
    const location = useLocation<{ background: Location }>();
    const isProfileStarted = useSelector(selectUserDataIsProfileStarted);
    const tweets = useSelector(selectTweetsItems);
    const isLoading = useSelector(selectIsTweetsLoading);
    const pagesCount = useSelector(selectPagesCount);
    const newTweetCount = useSelector(selectNewTweetCount);
    // const containerRef = React.useRef<any>();
    const [switchTweets, setSwitchTweets] = React.useState<boolean>(false);
    const [page, setPage] = React.useState<number>(0);
    const [scrollTop, setScrollTop] = React.useState(0);
    const [itemHeights, setItemHeights] = React.useState(new Map<number, number>());
    const [containerHeight, setContainerHeight] = React.useState(window.innerHeight);
    const [zIndexs, setZIndexs] = React.useState<any>({});

    const totalHeight = React.useRef(0); // Track the total height of all posts
    const resizeObserver = React.useRef<ResizeObserver | null>(null);
    const containerRef = React.useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        dispatch(setTweetsLoadingState(LoadingStatus.NEVER));
        dispatch(setTweetCountReset());
        // dispatch(fetchUserData());

        if (location.pathname !== SEARCH) {
            loadTweets();
        }
        document.body.style.overflow = "unset";
        window.scrollTo(0, 0);

        return () => {
            dispatch(resetTweets());
            dispatch(setTweetCountReset());
        };
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            setScrollTop(window.scrollY-200);
        };

        const handleResize = () => {
            setContainerHeight(window.innerHeight-200);
        };

        window.addEventListener("scroll", handleScroll);
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("scroll", handleScroll);
            window.removeEventListener("resize", handleResize);
        };
    }, [dispatch])

    useEffect(() => {
        totalHeight.current = Array.from(itemHeights.values()).reduce(
            (sum, height) => sum + height,
            0
        );
    }, [itemHeights]);

    useEffect(() => {
        return () => resizeObserver.current?.disconnect();
    }, []);

    const updateHeight = (index: number, height: number) => {
        setItemHeights((prev) => {
            const newHeights = new Map(prev);
            if (newHeights.get(index) !== height) {
                newHeights.set(index, height);
                totalHeight.current = Array.from(newHeights.values()).reduce(
                    (sum, h) => sum + h,
                    0
                );
            }
            return newHeights;
        });
    };

    const observeResize = (index: number, element: HTMLDivElement | null) => {
        if (!resizeObserver.current) {
            resizeObserver.current = new ResizeObserver((entries) => {
                entries.forEach((entry: any) => {
                    const height = entry.contentRect.height;
                    const observedIndex = parseInt(
                        entry.target.dataset.index || "0",
                        10
                    );
                    updateHeight(observedIndex, height);
                });
            });
        }
        if (element) {
            element.dataset.index = index.toString();
            resizeObserver.current.observe(element);
        }
    };

    const handleItemResize = (index: number, height: number) => {
        setItemHeights((prev) => {
            const newHeights = new Map(prev);
            newHeights.set(index, height);
            return newHeights;
        });
    };

    const handleZIndex = (index: number) => {

    }

    const loadTweets = (): void => {
        if (switchTweets) {
            dispatch(fetchFollowersTweets(page));
        } else {
            dispatch(fetchTweets(page));
        }
        setPage((prevState) => prevState + 1);
    };

    const handleLatestTweets = (): void => {
        dispatch(resetTweets());
        dispatch(fetchFollowersTweets(0));
        handleSwitchTweets(true);
    };

    const handleTopTweets = (): void => {
        dispatch(resetTweets());
        dispatch(fetchTweets(0));
        handleSwitchTweets(false);
    };

    const handleSwitchTweets = (condition: boolean): void => {
        setSwitchTweets(condition);
        setPage((prevState) => prevState + 1);
    };

    const handleNewTweetCount = (): void => {
        setPage(0);
        dispatch(setTweetCountReset());
        switchTweets ? handleLatestTweets() : handleTopTweets();
    };

    // Calculate visible range
    let cumulativeHeight = 0;
    const visibleStartIndex =
        Math.max(
        Array.from(itemHeights.entries()).findIndex(([_, height]) => {
            cumulativeHeight += height || 0;
            return cumulativeHeight >= scrollTop;
        }),
        0
    );

    const visibleEndIndex = Math.min(
        visibleStartIndex +
            Math.ceil(containerHeight / (itemHeights.get(visibleStartIndex) || 1)),
        tweets.length
    );

    const visibleTweets = tweets.slice(visibleStartIndex, visibleEndIndex);
    const visibleTweetsIndex = visibleTweets.length + 1
    return (
        <InfiniteScroll
            style={{ overflow: "unset" }}
            dataLength={tweets.length}
            next={loadTweets}
            hasMore={page < pagesCount}
            loader={null}
        > 
            <div ref={containerRef}>
                <Paper className={classnames(globalClasses.pageContainer, classes.container)} variant="outlined">
                    <Paper className={classnames(globalClasses.pageHeader, classes.header)} variant="outlined">
                        <Typography variant="h5">Home</Typography>
                        <TopTweetActions
                            switchTweets={switchTweets}
                            handleLatestTweets={handleLatestTweets}
                            handleTopTweets={handleTopTweets}
                        />
                    </Paper>
                    <div className={classes.addForm}>
                        <AddTweetForm title={"What's happening?"} buttonName={"Tweet"} />
                    </div>
                    <Divider />
                    {SHOW_POST < newTweetCount && (
                        <>
                            <div role="button" tabIndex={0} onClick={handleNewTweetCount} className={classes.showPost}>
                                <Typography variant={"body1"} component={"span"}>
                                    Show {newTweetCount} posts
                                </Typography>
                            </div>
                            <Divider style={{margin: "1px"}}/>
                        </>
                    )}
                    {!isProfileStarted ? (
                        <Welcome />
                    ) : (
                        <>
                            {/* <FixedSizeList
    							height={1000}
    							width={'100%'}
    							itemCount={tweets.length}
    							itemSize={61}
                            >
                                {Row}
                            </FixedSizeList> */}
                           {/* <VariableSizeList
                                height={window.innerHeight-200}
                                itemCount={tweets.length}
                                itemSize={getItemSize}
                                width={"100%"}
                            >
                                {Row}
                            </VariableSizeList> */}
                        <div
                        style={{
                            height: `${totalHeight.current}px`,
                            position: "relative",
                        }}
                    >
                        {visibleTweets.map((tweet, index) => {
                            const actualIndex = visibleStartIndex + index;
                            
                            return (
                                <div
                                    key={tweet.id}
                                    ref={(el) => {
                                        if (el) {
                                            const height = el.getBoundingClientRect().height;
                                            if (height !== itemHeights.get(actualIndex)) {
                                                handleItemResize(actualIndex, height);
                                            }
                                        }
                                    }}
                                    style={{
                                        position: "absolute",
                                        transform: `translateY(${Array.from(itemHeights.entries())
                                            .slice(0, actualIndex)
                                            .reduce(
                                                (sum, [_, height]) => sum + (height || 0),
                                                0
                                            )}px)`,
                                        width: "100%",
                                        overflow: "visible",
                                        zIndex: visibleTweetsIndex - index
                                    }}
                                >
                                    <TweetComponent key={tweet.id} tweet={tweet} />
                                </div>
                            );
                        })}
                    </div>
                    {/* {page < pagesCount && (
                        <div
                            style={{ textAlign: "center", margin: "20px 0" }}
                            onClick={loadTweets}
                        >
                            <Spinner />
                        </div>
                    )} */}
                            {/* {tweets.map((tweet) => (
                                <TweetComponent key={tweet.id} tweet={tweet} />
                            ))} */}
                            {isLoading && <Spinner />}
                        </>
                    )}
                </Paper>
            </div>
        </InfiniteScroll>
    );
};

export default withDocumentTitle(Home)("Home");
