import { AxiosResponse } from "axios";

import { DeclinedTweetsResponse, TweetAdditionalInfoResponse, TweetImageResponse, TweetResponse } from "../../../types/tweet";
import { axios } from "../../../core/axios";
import {
    UI_V1_TWEETS,
    UI_V1_TWEETS_CHANGE_REPLY,
    UI_V1_TWEETS_FOLLOWER,
    UI_V1_TWEETS_ID,
    UI_V1_TWEETS_IMAGE_TAGGED,
    UI_V1_TWEETS_IMAGES_USER_ID,
    UI_V1_TWEETS_ID_INFO,
    UI_V1_TWEETS_MEDIA,
    UI_V1_TWEETS_MEDIA_USER_ID,
    UI_V1_TWEETS_QUOTE,
    UI_V1_TWEETS_ID_QUOTES,
    UI_V1_TWEETS_ID_REPLIES,
    UI_V1_TWEETS_REPLY,
    UI_V1_TWEETS_SEARCH_TEXT,
    UI_V1_TWEETS_UPLOAD,
    UI_V1_TWEETS_USER_ID,
    UI_V1_TWEETS_VIDEO,
    UI_V1_PINNED_TWEET_USER_ID,
    UI_V1_LATEST_TWEETS,
    UI_V1_TOP_TWEETS,
    UI_V1_TWEETS_VERACITY_VERIFY,
    UI_V1_TWEETS_MEDIA_TAB,
    UI_V1_DECLINED_TWEETS,
    UI_V1_APPLY_FOR_DECLINED_TWEETS
} from "../../../constants/endpoint-constants";
import { UserTweetRequest } from "../../../store/ducks/userTweets/contracts/state";
import { Image } from "../../../types/common";
import {
    AddQuoteTweetRequest,
    ChangeReplyTypeRequest,
    TweetRequest,
    FetchTweetsBySearchRequest,
    DeclinedTweetPayload
} from "../../../store/ducks/tweets/contracts/state";
import { FetchTweetUsersPayload, ReplyTweetRequest } from "../../../store/ducks/tweet/contracts/state";
import { UserResponse } from "../../../types/user";
import { VeracityTweetInfoState } from '../../../store/ducks/tweetAdditionalInfo/contracts/state';

export const TweetApi = {
    async getTweets(pageNumber: number): Promise<AxiosResponse<TweetResponse[]>> {
        return await axios.get<TweetResponse[]>(UI_V1_TWEETS, { params: { page: pageNumber } });
    },
    async getTweetById(tweetId: string): Promise<AxiosResponse<TweetResponse>> {
        return await axios.get<TweetResponse>(UI_V1_TWEETS_ID(tweetId));
    },
    async getPinnedTweetByUserId(userId: string): Promise<AxiosResponse<TweetResponse>> {
        return await axios.get<TweetResponse>(UI_V1_PINNED_TWEET_USER_ID(userId));
    },
    async getUserTweets({ userId, page }: UserTweetRequest): Promise<AxiosResponse<TweetResponse[]>> {
        return await axios.get<TweetResponse[]>(UI_V1_TWEETS_USER_ID(userId), { params: { page: page } });
    },
    async getUserMediaTweets({ userId, page }: UserTweetRequest): Promise<AxiosResponse<TweetResponse[]>> {
        return await axios.get<TweetResponse[]>(UI_V1_TWEETS_MEDIA_USER_ID(userId), { params: { page: page } });
    },
    async getUserTweetImages(userId: string): Promise<AxiosResponse<TweetImageResponse[]>> {
        return await axios.get<TweetImageResponse[]>(UI_V1_TWEETS_IMAGES_USER_ID(userId));
    },
    async getTweetAdditionalInfoById(tweetId: string): Promise<AxiosResponse<TweetAdditionalInfoResponse>> {
        return await axios.get<TweetAdditionalInfoResponse>(UI_V1_TWEETS_ID_INFO(tweetId));
    },
    async getRepliesByTweetId(tweetId: string): Promise<AxiosResponse<TweetResponse[]>> {
        return await axios.get<TweetResponse[]>(UI_V1_TWEETS_ID_REPLIES(tweetId));
    },
    async getQuotesByTweetId(tweetId: string, pageNumber: number): Promise<AxiosResponse<TweetResponse[]>> {
        return await axios.get<TweetResponse[]>(UI_V1_TWEETS_ID_QUOTES(tweetId), { params: { page: pageNumber } });
    },
    async getMediaTweets(request: FetchTweetsBySearchRequest): Promise<AxiosResponse<TweetResponse[]>> {
        return await axios.get<TweetResponse[]>(UI_V1_TWEETS_MEDIA_TAB, { params: { page: request.pageNumber, search: request.search } });
    },
    async getTweetsWithVideo(request: FetchTweetsBySearchRequest): Promise<AxiosResponse<TweetResponse[]>> {
        return await axios.get<TweetResponse[]>(UI_V1_TWEETS_VIDEO, { params: { page: request.pageNumber, search: request.search } });
    },
    async getFollowersTweets(pageNumber: number): Promise<AxiosResponse<TweetResponse[]>> {
        return await axios.get<TweetResponse[]>(UI_V1_TWEETS_FOLLOWER, { params: { page: pageNumber } });
    },
    async uploadTweetImage(formData: FormData): Promise<AxiosResponse<Image>> {
        return await axios.post<Image>(UI_V1_TWEETS_UPLOAD, formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
    },
    async getTaggedImageUsers({ tweetId, pageNumber }: FetchTweetUsersPayload): Promise<AxiosResponse<UserResponse[]>> {
        return await axios.get<UserResponse[]>(`${UI_V1_TWEETS_IMAGE_TAGGED}/${tweetId}`, {
            params: { page: pageNumber }
        });
    },
    async createTweet(request: TweetRequest): Promise<AxiosResponse<TweetResponse>> {
        return await axios.post<TweetResponse>(UI_V1_TWEETS, request);
    },
    async deleteTweet(tweetId: string): Promise<AxiosResponse<string>> {
        return await axios.delete<string>(`${UI_V1_TWEETS}/${tweetId}`);
    },
    async searchTweets(text: string, pageNumber: number): Promise<AxiosResponse<TweetResponse[]>> {
        return await axios.get<TweetResponse[]>(UI_V1_TWEETS_SEARCH_TEXT(text), { params: { page: pageNumber } });
    },
    async replyTweet(request: ReplyTweetRequest): Promise<AxiosResponse<TweetResponse>> {
        return await axios.post<TweetResponse>(
            `${UI_V1_TWEETS_REPLY}/${request.userId ?? 0}/${request.tweetId}`,
            request
        );
    },
    async quoteTweet(request: AddQuoteTweetRequest): Promise<AxiosResponse<TweetResponse>> {
        return await axios.post<TweetResponse>(
            `${UI_V1_TWEETS_QUOTE}/${request.userId ?? 0}/${request.tweetId}`,
            request
        );
    },
    async changeTweetReplyType(request: ChangeReplyTypeRequest): Promise<AxiosResponse<TweetResponse>> {
        return await axios.get<TweetResponse>(
            `${UI_V1_TWEETS_CHANGE_REPLY}/${request.userId ?? 0}/${request.tweetId}`,
            { params: { replyType: request.replyType } }
        );
    },
    async getVeracityTweet(request: VeracityTweetInfoState): Promise<AxiosResponse<TweetResponse[]>> {
      return await axios.post<TweetResponse[]>(UI_V1_TWEETS_VERACITY_VERIFY(request.userId), { tweet: request.tweet, tweet_id: request.tweet_id });
    },
    async getTopTweets(request: FetchTweetsBySearchRequest): Promise<AxiosResponse<TweetResponse[]>> {
        return await axios.get<TweetResponse[]>(UI_V1_TOP_TWEETS, {
            params: { page: request.pageNumber, search: request.search }
        });
    },
    async getLatestTweets(request: FetchTweetsBySearchRequest): Promise<AxiosResponse<TweetResponse[]>> {
        return await axios.get<TweetResponse[]>(UI_V1_LATEST_TWEETS, {
            params: { page: request.pageNumber, search: request.search }
        });
    },
    async getDeclinedTweets(request: DeclinedTweetPayload): Promise<AxiosResponse<any>> {
        return await axios.get<any>(UI_V1_DECLINED_TWEETS(request.userId ?? 0, request.pageNumber))
    },
    async appealApply(request: { contentId: string; userReason: string }): Promise<AxiosResponse<DeclinedTweetsResponse[]>> {
        return await axios.post<DeclinedTweetsResponse[]>(UI_V1_APPLY_FOR_DECLINED_TWEETS, request);
    }
};
