import { makeStyles } from "@material-ui/core";
import { BACKGROUND } from "../../../constants/common-constants";
import { BackgroundTheme } from "../../../types/common";

export const useDeclinedTweetHeaderStyles = makeStyles(() => ({
    policyNames: {
        display: "flex",
        gap: "10px",
        marginTop: "10px"
    },
    errorChip: {
        backgroundColor: localStorage.getItem(BACKGROUND) === BackgroundTheme.DEFAULT ? "rgb(244, 67, 54)" : "white",
        color: localStorage.getItem(BACKGROUND) === BackgroundTheme.DEFAULT ? "white" : "rgb(244, 67, 54)",
        fontWeight: 500,
        textTransform: "capitalize"
    },
}));
