import React, { FC, memo, ReactElement } from "react";

import { ReplyIcon } from "../../../icons";
import ActionIconButton from "../../../components/ActionIconButton/ActionIconButton";
import { useReplyIconButtonStyles } from "./ReplyIconButtonStyles";
import { useGlobalStyles } from "../../../util/globalClasses";
import classNames from "classnames";
import { IconButton, Tooltip, withStyles } from "@material-ui/core";

interface ReplyIconButtonProps {
  repliesCount?: number;
  isDisable?: boolean;
}

const ReplyIconButton: FC<ReplyIconButtonProps> = memo(({ repliesCount, isDisable }
): ReactElement => {
    const globalClasses = useGlobalStyles({});
    const classes = useReplyIconButtonStyles();

    const [count, setCount] = React.useState<number>(repliesCount || 0);
    const [animationLike, setAnimationLike] = React.useState(globalClasses.countInit);

    React.useEffect(() => {
      if (repliesCount !== undefined && repliesCount !== count) {
          setCount(repliesCount);
          setAnimationLike(repliesCount > count ? globalClasses.countGoUp : globalClasses.countGoDown);
          setTimeout(() => setAnimationLike(globalClasses.countInit), 100); // Set 100 milliseconds time becasuse of the animation delay is 0.1s
      }
  }, [repliesCount]);

  const BlackToolTip = withStyles({
    tooltip: {
      backgroundColor: "rgba(0, 0, 0, 0.6)"
    }
  })(Tooltip);

    return (
        <div className={classes.infoIcon}>
            {isDisable ? (
                <BlackToolTip title="Replies are disabled for this post.">
                    <IconButton aria-label="Replies are disabled for this post.">
                        <>{<ReplyIcon />}</>
                    </IconButton>
                </BlackToolTip>
            ) : (
                <ActionIconButton actionText={"Reply"} icon={<ReplyIcon />} disabled={isDisable} />
            )}

            {count !== 0 && (
                <span id={"repliesCount"} className={classNames(classes.repliesCount, animationLike)}>
                    {count}
                </span>
            )}
        </div>
    );
});

export default ReplyIconButton;
