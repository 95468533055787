import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Typography
} from "@material-ui/core";
import { useFullDeclinedTweetModalStyles } from "./FullDeclinedTweetModalStyles";
import { useSelector } from "react-redux";
import { selectDeclinedTweetState } from "../../../../store/ducks/tweets/selectors";
import React from "react";
import TweetAvatar from "../../TweetAvatar/TweetAvatar";
import TweetHeader from "../../TweetHeader/TweetHeader";
import { DEFAULT_PROFILE_IMG } from "../../../../constants/url-constants";
import TweetText from "../../TweetText/TweetText";
import TweetImage from "../../TweetImage/TweetImage";
import TweetMedia from "../../TweetMedia/TweetMedia";
import TweetDeleted from "../../../TweetDeleted/TweetDeleted";
import Quote from "../../../Quote/Quote";
import { textFormatter } from "../../../../util/text-formatter";
import { formatDate } from "../../../../util/format-date-helper";
import { useQuoteStyles } from "../../../Quote/QuoteStyles";

interface IProps {
    tweetId: string | number;
    isOpen: boolean;
    handleClose: () => void;
    appeal: any;
}

const FullDeclinedTweetModal = ({ tweetId, isOpen, handleClose, appeal }: IProps) => {
    const classes = useFullDeclinedTweetModalStyles();
    const quoteClasses = useQuoteStyles();

    const declinedTweets = useSelector(selectDeclinedTweetState);
    const tweet = React.useMemo(() => {
        return declinedTweets.find((tweet: any) => tweet?.id == tweetId);
    }, [declinedTweets, tweetId]);

    return (
        <Dialog open={isOpen} onClose={handleClose} maxWidth="lg">
            <DialogTitle style={{ padding: "10px 10px" }}>View Appeal</DialogTitle>
            <DialogContent style={{ padding: 0 }}>
                <div className={classes.modalWrapper}>
                    {/* <div className={classes.content}>
                        <div className={classes.contentText}>
                            <strong>You'r response:</strong> <i>{appeal?.userReason}</i>
                        </div>
                        {appeal?.adminResponse && <div>Admin Response: <i>{appeal?.adminResponse}</i></div>}
                    </div> */}
                    <div className={classes.container}>
                        <div className={classes.tweetWrapper}>
                            <TweetAvatar
                                userId={tweet?.author.id}
                                src={tweet?.author.avatar ?? DEFAULT_PROFILE_IMG}
                            />
                            <div className={classes.tweetContainer}>
                                <div className={classes.header}>
                                    <TweetHeader
                                        userId={tweet?.author.id}
                                        fullName={tweet?.author.fullName}
                                        username={tweet?.author.username}
                                        isPrivateProfile={tweet?.author.isPrivateProfile}
                                        dateTime={tweet?.createdAt}
                                    />
                                </div>
                                <div className={classes.tweetContent}>
                                    <TweetText
                                        text={tweet?.text}
                                        tweetId={tweet?.id}
                                        isDeclined
                                    />
                                    {tweet?.images?.length !== 0 && (
                                        <TweetImage
                                            tweetId={tweet?.id}
                                            images={tweet?.images as any[]}
                                            imageDescription={tweet?.imageDescription}
                                            taggedImageUsers={tweet?.taggedImageUsers}
                                        />
                                    )}
                                  
                                    <TweetMedia
                                        link={tweet?.link}
                                        linkTitle={tweet?.linkTitle}
                                        linkDescription={tweet?.linkDescription}
                                        linkCover={tweet?.linkCover}
                                        linkCoverSize={tweet?.linkCoverSize}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={classes.status}>
                            <Chip
                                label={appeal?.status?.toLowerCase()}
                                size="small"
                                className={
                                    appeal?.status === "PENDING"
                                        ? classes.pendingChip
                                        : appeal?.status === "APPROVE"
                                        ? classes.approveChip
                                        : classes.errorChip
                                }
                            />
                        </div>
                    </div>
                    
                    {appeal && appeal?.userReason && (
                        <div className={quoteClasses.quoteTweetLink}>
                            <div className={quoteClasses.quoteTweetContainer}>
                                <div className={quoteClasses.quoteTweetWrapper}>
                                    <Typography component={"span"} className={quoteClasses.quoteTweetFullName}>
                                        {`User Appeal`}
                                    </Typography>
                                    &nbsp;
                                    <Typography component={"span"} className={quoteClasses.quoteTweetUsername}>
                                        {formatDate(new Date(appeal.createdAt))}
                                    </Typography>
                                </div>
                                <Typography component={"div"} className={quoteClasses.quoteTweetText}>
                                    {textFormatter(appeal.userReason)}
                                </Typography>
                            </div>
                            {/* <i>{appeal?.userReason}</i> */}
                        </div>
                    )}

                    {/* Admin Response */}
                    {appeal && appeal?.adminResponse && (
                        <div className={quoteClasses.quoteTweetLink}>
                            <div className={quoteClasses.quoteTweetContainer}>
                                <div className={quoteClasses.quoteTweetWrapper}>
                                    <Typography component={"span"} className={quoteClasses.quoteTweetFullName}>
                                        {`Admin Response`}
                                    </Typography>
                                    &nbsp;
                                    <Typography component={"span"} className={quoteClasses.quoteTweetUsername}>
                                        {formatDate(new Date(appeal.updatedAt))}
                                    </Typography>
                                </div>
                                <Typography component={"div"} className={quoteClasses.quoteTweetText}>
                                    {textFormatter(appeal.adminResponse)}
                                </Typography>
                            </div>
                        </div>
                    )}
                </div>
            </DialogContent>
            <DialogActions>
                <div className={classes.modalButtonWrapper}>
                    <Button
                        className={classes.modalCancelButton}
                        onClick={handleClose}
                        variant="contained"
                        size="large"
                    >
                        Cancel
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    );
};

export default FullDeclinedTweetModal;
